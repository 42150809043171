html,
body {
}

body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background: #fcfcfe;
}
.animated {
  animation-duration: 500ms;
}

.border-s {
  border: 1px solid #ccc;
}

.sign-up-main {
  padding: 30px 80px;
  border-bottom: 6px solid #d92024 !important;
  margin-top: 30px;
  margin-bottom: 0px;
  h2 {
    font-size: 30px;
    position: relative;
    font-weight: bold;
    color: #152733;
    span {
      &.red-border-before {
        width: 66px;
        content: "";
        background: #ec2024;
        height: 3px;
        display: block;
        margin-right: 30px;
      }
      &.red-border-after {
        width: 66px;
        content: "";
        background: #ec2024;
        height: 3px;
        display: block;
        margin-left: 30px;
      }
    }
  }

  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1c0c0;
    border-radius: 7px;
    height: 45px;
  }
  select {
    border: 1px solid #c1c0c0;
    border-radius: 7px;
    height: 45px;
    color: #cdc7d6 !important;
    font-size: 13px;
  }
  p {
    &.text-1 {
      color: #7b7b7b;
      font-size: 16px;
    }
  }
  a {
    &.link-1 {
      color: #4978fb;
      text-decoration: underline;
    }
  }
  a {
    font-size: 16px;
    text-decoration: underline;
  }
  button {
    background: transparent linear-gradient(180deg, #ec2024 0%, #be2025 100%) 0% 0% no-repeat padding-box;
    font-size: 16px;
    padding: 8px;
    color: #fff;
    border-radius: 10px;
    width: 220px;
  }

  button {
    &:hover {
      color: #fff;
    }
  }
  input {
    &::-webkit-input-placeholder {
      color: #c4c4c4;
    }
    &:-ms-input-placeholder {
      color: #c4c4c4;
    }
    &::placeholder {
      color: #c4c4c4;
    }
  }

  .host-text {
    min-height: 60px;
    .dropdown-menu {
      &::after {
        position: absolute;
        top: -7px;
        left: 50%;
        margin-left: -3px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #0e90ff;
        content: "";
      }
    }
    button {
      font-size: 16px;
      font-weight: 600;
      color: #0e90ff;
      border: 1px solid #0e90ff;
      border-radius: 7px;
      background: none;
      width: 100%;
      display: block;
      padding: 10px 10px;
    }
  }
  .host-text {
    button {
      &:hover {
        color: #fff;
        background: #0e90ff;
        border: 1px solid #0e90ff;
      }
    }
    button.active {
      color: #fff;
      background: #0e90ff;
      border: 1px solid #0e90ff;
    }
  }
  .host-text {
    button {
      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    button.active {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .host-text {
    .dropdown-menu {
      background: #0e90ff;
      top: 52px;
      width: 100%;
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .participant-text {
    button {
      font-size: 16px;
      font-weight: 600;
      color: #ff7556;
      border: 1px solid #ff7556;
      border-radius: 7px;
      background: none;
      width: 100%;
      display: block;
      padding: 10px 10px;
      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
        color: #ffff;
        border: 1px solid #ff7556;
        background: #ff7556;
      }
    }
    button.active {
      img {
        filter: brightness(0) invert(1);
      }
      color: #ffff;
      border: 1px solid #ff7556;
      background: #ff7556;
    }
    .dropdown-menu {
      background: #ff7556;
      top: 52px;
      width: 100%;
      &::after {
        position: absolute;
        top: -7px;
        left: 50%;
        margin-left: -3px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #ff7556;
        content: "";
      }
    }
    min-height: 65px;
  }
  .dropdown-item {
    padding: 0 10px;
    text-align: center;
    font-size: 11px;
    color: #fff;
    width: 100%;
    white-space: inherit;
    &:focus {
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
    }
  }
  h1 {
    color: #152733;
    font-size: 30px;
  }
  h5 {
    color: #152733;
    font-size: 20px;
  }
  .dropdown {
    &:hover {
      > .dropdown-menu {
        display: block;
      }
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../images/signup_login/arrow-down-sign-to-navigate.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right 15px bottom 17px;
  }
  &.p-custom {
    padding: 50px 40px;
  }
  &.reset-pwd {
    h2 {
      &::before {
        left: 0px;
      }
      &::after {
        right: 0px;
      }
    }
  }
}

.signup-mt-10 {
  margin-top: 10px;
}

.login-page-main {
  h2 {
    &::before {
      left: 140px;
    }
    &::after {
      right: 140px;
    }
  }
}

.cmp-signup-line {
  h2 {
    &::before {
      left: 70px;
    }
    &::after {
      right: 70px;
    }
  }
}

.sso-submit {
  background: transparent linear-gradient(180deg, #ec2024 0%, #be2025 100%) 0% 0% no-repeat padding-box !important;
  font-size: 16px;
  padding: 8px;
  color: #fff;
  border-radius: 10px;
  width: 220px;
  font-weight: 400;
  transition: all 0.15s ease-out;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.sso-submit {
  &:hover {
    color: #fff;
    cursor: pointer;
  }
}

.purchase-licence-main {
  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #152733;
  }
  p {
    color: #8d969c;
    font-size: 16px;
  }
  ul {
    padding-left: 20px;
    list-style: none;
    li {
      color: #8d969c;
      font-size: 16px;
      position: relative;
      &::before {
        position: absolute;
        background: #0e90ff;
        border-radius: 100%;
        height: 10px;
        width: 10px;
        content: "";
        left: -20px;
        top: 7px;
      }
    }
  }
  .custom-img {
    height: 45px;
  }
  .save-btn {
    color: #fff;
    background-color: #ff7556;
    border-color: #ff7556;
    &:hover {
      color: #fff;
      background-color: #f1947f;
      border-color: #f1947f;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../images/signup_login/arrow-down-sign-to-navigate.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right 15px bottom 17px;
    border: 1px solid #ebebeb;
    padding-right: 40px;
    border-radius: 7px;
    height: 45px;
    color: #152733;
    font-size: 16px;
  }
  input {
    border: 1px solid #ebebeb;
    padding-right: 20px;
    border-radius: 7px;
    height: 45px;
    color: #152733;
    font-size: 16px;
  }
  .licence-btn {
    background-color: #0e90ff;
    border-color: #0e90ff;
    font-size: 16px;
    font-weight: bold;
  }

  [type="radio"] {
    &:checked {
      position: absolute;
      left: -9999px;
    }
    &:not(:checked) {
      position: absolute;
      left: -9999px;
    }
  }
  [type="radio"] {
    &:checked {
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #152733;
          font-size: 16px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background: #0e90ff;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
    &:not(:checked) {
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #152733;
          font-size: 16px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background: #0e90ff;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }
      }
    }
  }
}

.text-dark-blue {
  color: #152733;
}
.save-btn {
  color: #fff;
  background-color: #ff7556;
  border-color: #ff7556;
  &:hover {
    color: #fff;
    background-color: #f1947f;
    border-color: #f1947f;
  }
}

.common-main {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../images/signup_login/arrow-down-sign-to-navigate.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right 15px bottom 17px;
    border: 1px solid #ebebeb;
    padding-right: 40px;
    border-radius: 7px;
    height: 45px;
    color: #152733;
    font-size: 16px;
  }
  input {
    border: 1px solid #ebebeb;
    padding-right: 20px;
    border-radius: 7px;
    height: 45px;
    color: #152733;
    font-size: 16px;
  }
}

.common-modal {
  .modal-header {
    background: #e2e2e2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    h5 {
      font-size: 20px;
      line-height: normal;
    }
    span {
      background: #7b7b7b;
      padding: 5px;
      display: flex;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
    }
    .close {
      text-shadow: none;
      color: #fff;
    }
  }
  .modal-content {
    border-radius: 15px;
  }
  .modal-footer {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .save-btn2 {
    background-color: #0e90ff;
    border-color: #0e90ff;
    &:hover {
      border-color: #2797e8;
      background-color: #2797e8;
    }
  }
  .w-custom-1 {
    width: 100px;
  }
  .modal-lg {
    max-width: 700px;
  }
  .table {
    td {
      border-top: 0px solid #dee2e6;
      padding-top: 0;
      padding-bottom: 0;
      border-bottom-width: 0px;
      text-align: left;
    }
  }
  .scroll-main {
    border: 1px solid #ebebeb;
    font-size: 14px;
    padding: 10px 5px;
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  button {
    &:focus {
      outline: 1px dotted;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
}

.common-radio-btn {
  [type="radio"] {
    &:checked {
      position: absolute;
      left: -9999px;
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #152733;
          font-size: 16px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background: #0e90ff;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
    &:not(:checked) {
      position: absolute;
      left: -9999px;
      + {
        label {
          position: relative;
          padding-left: 26px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #152733;
          font-size: 16px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background: #0e90ff;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }
      }
    }
  }
}

.tab-common {
  padding: 0 0px;
  .nav-tabs {
    .nav-link {
      border-radius: 0;
      color: #152733;
      font-size: 16px;
      font-weight: bold;
      padding: 20px 20px;
      &:focus {
        border-color: transparent;
      }
      &:hover {
        border-color: transparent;
      }
    }
    .nav-item.show {
      .nav-link {
        color: #ec2024;
        background-color: #f9f9f9;
        border-color: transparent;
        border-bottom: 1px solid #dee2e6 !important;
        text-decoration: underline;
      }
    }
    .nav-link.active {
      color: #ec2024;
      background-color: #f9f9f9;
      border-color: transparent;
      border-bottom: 1px solid #dee2e6 !important;
      text-decoration: underline;
    }
    .nav-item {
      margin-bottom: 0;
    }
    padding: 0 15px;
  }
  .tab-content {
    padding: 30px;
  }
}

.new-event-main {
  .plus-icon {
    right: 15px;
    top: 3px;
  }
  .minus-icon {
    right: 20px;
    top: 20px;
  }
  .date-icon {
    right: 30px;
    top: 40px;
  }
}
.common-checkbox {
  .form-group {
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
      &:checked {
        + {
          label {
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 3px;
              left: 6px;
              width: 6px;
              height: 12px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
            &:before {
              background: #0e90ff;
              border: 1px solid #0e90ff;
            }
          }
        }
      }
    }
    label {
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #c4c4c4;
        padding: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }
}
.text-sky-blue {
  color: #0e90ff;
}
.orange-btn1 {
  border-color: #ff7556;
  background: #fff;
  color: #ff7556;
  height: 45px;
  &:hover {
    border-color: #ff7556;
    background: #ff7556;
    color: #ffff;
  }
}
.text-light-grey {
  color: #c5c5c5;
}
.check-show {
  display: block !important;
}
.alert-main {
  background: #fff5d6;
  width: calc(100% - 60px) !important;
  padding: 12px;
  border: 1px solid #f6c73e;
  font-size: 14px;
  border-radius: 10px;
  .fa-info-circle {
    color: #ff7556;
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 12px;
    color: #ff7556;
  }
}

.form-group {
  margin-bottom: 1rem;
}
.text-right {
  text-align: right !important;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.vertical-align-bottom1 {
  vertical-align: text-bottom;
}
.custom-radius-1.dropdown-menu {
  border-radius: 5px 5px 5px 5px !important;
}
.signup-recaptcha {
  margin: 0 auto 15px auto;
  display: flex;
  justify-content: center;
}
.select-custom-text {
  color: #9d9d9d !important;
}
.passwd-shw-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 999;
}
.opacity-1 {
  opacity: 1 !important;
}
.hidden {
  display: none !important;
}

.psw_error_visible {
  ul {
    padding-left: 0px !important;
  }
}
.select2-container--default {
  .select2-selection--single {
    border: 1px solid #ebebeb !important;
    padding-top: 0px !important;
    height: 40px !important;
    border-radius: 7px !important;
    color: #ebebeb !important;
    .select2-selection__arrow {
      top: 3px !important;
      color: #ebebeb !important;
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      border-radius: 25px;
    }
  }
}
.select2-container {
  width: 100% !important;
}
.select2-selection__rendered {
  color: #c5c4c5 !important;
}
.sign-up-main {
  select {
    &::-webkit-input-placeholder {
      color: #c4c4c4;
    }
    &:-ms-input-placeholder {
      color: #c4c4c4;
    }
    &::placeholder {
      color: #c4c4c4;
    }
  }
}

#page-container {
  height: 100%;
  padding-bottom: 0px;
}
#main-container {
  height: 94%;
  padding-bottom: 0px;
}

#page-container {
  background-color: #fbfbfb;
}
#page-footer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}
.nav-header {
  li.header-btn {
    a {
      background: #ec204b;
      color: #fff;
      display: block;
      line-height: inherit;
      padding: 8px 10px;
      border-radius: 2px;
      -webkit-transition: all 0.15s ease-out;
      -moz-transition: all 0.15s ease-out;
      -ms-transition: all 0.15s ease-out;
      -o-transition: all 0.15s ease-out;
      transition: all 0.15s ease-out;
      margin-left: 7px;
      margin-top: 10px !important;
      background: red;
      float: right;
      text-align: right;
      margin-top: 5px;
      text-decoration: none;
    }
  }
}

#flash_messages {
  .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }
  .fade {
    &:not(.show) {
      opacity: 1;
    }
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.navbar-toggler {
  background: red;
  float: right;
  text-align: right;
  margin-right: 20px;
}

.login-box {
  top: 10px !important;
}

// alert messages on signup and login page.
.alert-msg-main {
  display: flex;
  flex-direction: column;
}

.alert-info {
  margin-top: 10px;
  margin-bottom: 0px;
}

.header-navbar-fixed {
  #main-container {
    padding-top: 75px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .sign-up-main h2::before,
  .sign-up-main .h2::before {
    left: 0px;
  }
  .sign-up-main h2::after,
  .sign-up-main .h2::after {
    right: 0px;
  }

  .login-page-main h2::before,
  .login-page-main .h2::before {
    left: 60px;
  }
  .login-page-main h2::after,
  .login-page-main .h2::after {
    right: 60px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cmp-signup-line {
    h2::before,
    .h2::before {
      left: 0px;
    }

    h2::after,
    .h2::after {
      right: 0px;
    }
  }

  .sign-up-main {
    &.reset-pwd {
      h2 {
        &::before {
          left: -35px;
        }
        &::after {
          right: -35px;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1240px) {
  .nav-header li.header-btn a {
    padding: 8px 4px;
  }
}

@media screen and (max-width: 1199px) {
  .vertical-scroll-mobile {
    overflow-y: inherit !important;
  }
  .navbar-toggler {
    margin-top: 3px;
  }
  .vertical-scroll-mobile {
    .col-md-4 {
      width: 100%;
    }
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("../images/signup_login/burger.png") !important;
  }
}

@media (min-width: 992px) {
  .navbar-toggler {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .sign-up-main {
    h2 {
      &::before {
        left: 20px;
      }
      &::after {
        right: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .sign-up-main {
    .host-text {
      min-height: 60px;
    }
    .participant-text {
      min-height: 60px;
    }
    padding: 50px 20px;
    h2 {
      &::before {
        left: 10px;
        width: 30px;
        display: none;
      }
      &::after {
        right: 10px;
        width: 30px;
        display: none;
      }
      .red-border-before {
        display: none !important;
      }
      .red-border-after {
        display: none !important;
      }
    }
    h1 {
      font-size: 24px;
    }
  }
  .tab-common {
    .nav-tabs {
      .nav-link {
        padding: 8px;
      }
    }
  }
  #page-footer {
    position: static;
  }
  #main-container {
    height: 100%;
  }
}

@media (width: 1200px) {
  .nav-header {
    li {
      &.header-btn {
        a {
          padding: 10px 4px;
          font-size: 12px;
        }
      }
    }
  }
}
